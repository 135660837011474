import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AnalysisResponseDTO, MinimalAnalysisResponseDTO } from '@solverml/api';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AnalisysDocumentPreviewComponent } from './analisys-document-preview/analisys-document-preview.component';
import { AnalysisResultDataComponent } from './analysis-result-data/analysis-result-data.component';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';
import { lastValueFrom } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-analysis-result-list',
  templateUrl: './analysis-result-list.component.html',
  styleUrls: ['./analysis-result-list.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    MatButtonModule,
    AnalisysDocumentPreviewComponent,
    AnalysisResultDataComponent,
    MatProgressSpinnerModule,
  ],
})
export class AnalysisPreviewListComponent implements AfterContentInit {
  @Input() analyses: MinimalAnalysisResponseDTO[] = [];

  @Output() deleteAnalysis: EventEmitter<AnalysisResponseDTO> =
    new EventEmitter<AnalysisResponseDTO>();

  minimalSelectedAnalysis: MinimalAnalysisResponseDTO | null = null;
  selectedAnalysis: AnalysisResponseDTO | null = null;

  isDownloadingSelectedAnalysis = true;

  constructor(private labAnalysisService: LabAnalysisService) {}

  ngAfterContentInit(): void {
    if (this.analyses.length > 0 && !this.minimalSelectedAnalysis) {
      this.onSelectAnalysis(this.analyses[0]);
    }
  }

  onAnalysisRemoved(removedAnalysis: AnalysisResponseDTO) {
    this.deleteAnalysis.emit(removedAnalysis);
    const firstNotRemovedAnalysis = this.analyses.find(
      (analysis) => analysis.id !== removedAnalysis.id
    );
    this.onSelectAnalysis(firstNotRemovedAnalysis || null);
  }

  async onSelectAnalysis(analysis: MinimalAnalysisResponseDTO) {
    this.minimalSelectedAnalysis = analysis;
    if (analysis?.id) {
      this.isDownloadingSelectedAnalysis = true;
      await new Promise((resolve) => setTimeout(resolve));
      this.selectedAnalysis = await this.labAnalysisService.getAnalysis(
        analysis.id
      );
      this.isDownloadingSelectedAnalysis = false;
    }
  }
}
