<section class="main-section" *ngIf="progressStatus === fileStatus.Ready">
  <div>
    <h1>Files upload</h1>
    <app-drag-drop-files
      [allowedExtensions]="['.pdf']"
      [allowMultipleFiles]="true"
      [files]="files"
      (onFilesDropped)="onFilesDropped($event)"
    >
    </app-drag-drop-files>

    <!--Submit button-->
    <div class="submit-button-container" *ngIf="files.length > 0">
      <button mat-flat-button color="primary" (click)="uploadFiles()">
        Upload files
      </button>
    </div>

    <div class="queue-container">
      <app-pending-documents-list></app-pending-documents-list>
    </div>
  </div>
  <div
    class="last-analyses-container"
    [ngClass]="{
    'last-analyses-container-opened': lastAnalysesPanelOpened,
    }"
  >
    <app-last-analyses-list
      (onPanelOpenChange)="lastAnalysesOpenChange($event)"
    ></app-last-analyses-list>
  </div>
</section>
<section
  *ngIf="progressStatus === fileStatus.Uploading"
  class="loading-container"
>
  <mat-spinner></mat-spinner>
</section>
<section
  *ngIf="progressStatus === fileStatus.Uploaded"
  class="success-container"
>
  <div class="check-icon-container">
    <mat-icon class="check-icon"> check_circle </mat-icon>
  </div>
  <div class="success-message">
    <p>{{ files.length }} files uploaded successfully</p>
  </div>
  <div class="button-container">
    <button mat-flat-button color="primary" (click)="onCloseConfirmModal()">
      <mat-icon> close </mat-icon> Close
    </button>
  </div>
</section>
